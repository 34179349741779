export const mapAssetIdsToAutocompleteOption = (
  watchedAssetIds: any[],
  assets: { loaded: boolean; data: { label: string; value: string }[] },
  currentValue: any[]
) => {
  const selectedItems = watchedAssetIds
    .filter((id: string) => typeof id === 'string')
    .map((id: string) => assets.data?.find((asset) => asset.value === id))
    .filter((asset): asset is { label: string; value: string } => !!asset);

  if (selectedItems.length === 0 && currentValue.length > 0) {
    return;
  }

  if (
    currentValue.length === selectedItems.length &&
    currentValue.every(
      (item: { label: string; value: string }, i: number) => item.value === selectedItems[i].value
    )
  ) {
    return;
  }

  return selectedItems;
};
