import Router from './routes';
import { useTheme } from '@mui/material';
import { useEffect, useLayoutEffect } from 'react';
import { customScrollbar } from './styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings';
import { useAuthentication } from './features/authentication/context';

export default function App() {
  const { user, setIsClientReady } = useAuthentication();

  const theme = useTheme();
  useLayoutEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.paper;
  });

  const userId = user?.userId || `anonymous_${Math.random().toString(36).substring(7)}`;

  const { client } = useClientAsyncInit(process.env.REACT_APP_STATSIG_KEY || '', {
    userID: userId,
    email: user?.email || 'unknown',
    custom: {
      customerId: user?.customerId || 'unknown',
      environment: process.env.REACT_APP_ENV || 'development',
    },
  });

  useEffect(() => {
    if (client) {
      setIsClientReady(true);
    }
  }, [client, setIsClientReady]);

  const queryClient = new QueryClient();

  useEffect(() => {
    const styleElement = document.createElement('style');
    const selectors = ['body', '.custom-autocomplete-scrollbar'];
    selectors.forEach((selector) =>
      styleElement.appendChild(document.createTextNode(customScrollbar(selector, theme)))
    );
    document.head.appendChild(styleElement);
  }, [theme]);

  return (
    <StatsigProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <Router />
      </QueryClientProvider>
    </StatsigProvider>
  );
}
