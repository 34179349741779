import { useFormContext } from 'react-hook-form';
import { useOutletContext } from 'react-router';
import SidebarWrapper from '../sidebar/SidebarWrapper';
import { Button, Chip, Stack, TextField } from '@mui/material';
import { columnsPopulated } from '../../utils/columnsPopulated';
import TimeFrameSelect from '../sidebar/sidebar-inputs/TimeFrameSelect';
import { RHFAutocomplete } from 'src/components/minimals/form';
import { useEffect } from 'react';
import { mapAssetIdsToAutocompleteOption } from '../../utils/mapAssetIdsToAutocompleteOption';

type PDFReportSidebarProps = {
  onPreview: () => void;
  assets: { loaded: boolean; data: { label: string; value: string }[] };
};

export const PDFReportSidebar = ({ onPreview, assets }: PDFReportSidebarProps) => {
  const { setValue, watch, getValues } = useFormContext();
  const { disableActions } = useOutletContext<any>();

  const watchedAssetIds: any[] = watch('data.assetIds');
  const getAssetIds: any[] = getValues('data.assetIds') || [];
  useEffect(() => {
    if (!assets.loaded) return;
    const selectedItems = mapAssetIdsToAutocompleteOption(watchedAssetIds, assets, getAssetIds);
    if (selectedItems) {
      setValue('data.assetIds', selectedItems, {
        shouldValidate: true,
        shouldDirty: false,
      });
    } else return;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedAssetIds, assets.loaded, setValue]);

  return (
    <SidebarWrapper
      renderFooterContent={(onCollapse) => (
        <Stack flexDirection="row" justifyContent="flex-end">
          <Button
            onClick={() => {
              onPreview();
              onCollapse();
            }}
            disabled={!columnsPopulated(watch()?.data) || disableActions}
            variant="contained"
            color="primary"
            data-sm="preview-btn"
          >
            Preview
          </Button>
        </Stack>
      )}
    >
      <Stack sx={{ paddingBottom: '24px' }} gap="20px">
        <TimeFrameSelect
          onChange={(value) => setValue('data.timeFrame', value)}
          value={watch('data.timeFrame')}
          testId="data-report-time-frame"
        />
        <RHFAutocomplete
          name="data.assetIds"
          multiple
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          options={assets.data || []}
          defaultValue={[]}
          value={watch('data.assetIds') || []}
          onChange={(_, newVal) =>
            setValue('data.assetIds', newVal, { shouldValidate: true, shouldDirty: true })
          }
          size="small"
          renderInput={(p) => (
            <TextField
              {...p}
              inputProps={{
                ...p.inputProps,
                'data-sm': 'assetdropdown-item',
              }}
              fullWidth
              label="Assets"
            />
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                key={option.value}
                label={option.label}
                size="small"
              />
            ))
          }
        />
      </Stack>
    </SidebarWrapper>
  );
};
