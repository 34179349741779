import { TableRow, TableCell, Typography, IconButton, Stack, useTheme } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { MenuPopover, MenuPopoverItem } from 'src/components';
import { useHtmlElement, useToggle } from 'src/hooks';
import { ReportsService } from 'src/services';
import { Report, ReportType } from '../../types';
import {
  AssetReportIcon,
  DataReportIcon,
  // DuplicateCopyIcon,
  FavoriteIcon,
  MoreVerticalFillIcon,
  PDFReportIcon,
  UnfavoriteIcon,
} from 'src/assets';
import { useAuthentication } from 'src/features/authentication/context';
import { SYSTEM } from '../../config/config';
import { formatTime } from '../../helper';
import { useLocation, useNavigate } from 'react-router';
import {
  REPORTS_ASSET_PATH,
  REPORTS_BUILD_PREFIX,
  REPORTS_DATA_PATH,
  REPORTS_PDF_PATH,
} from 'src/routes/config';
import { DeleteConfirmationPopup } from '../delete-confirmation-popup';
import { ACCESS_ROLES } from 'src/config';

type TableRowItemProps = {
  row: Report;
  selected: boolean;
  handleDelete: () => void;
  handleFavoriteClick: (id: string, isError?: boolean) => void;
};

export const TableRowItemNew = ({
  handleDelete,
  row,
  selected,
  handleFavoriteClick,
}: TableRowItemProps) => {
  const tableCellStyle = { maxWidth: 'sm', whiteSpace: 'normal', wordWrap: 'break-word' };
  const { htmlElement, addHtmlElement, removeHtmlElement } = useHtmlElement();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const theme = useTheme();
  const { state, onToggle } = useToggle();

  const navigate = useNavigate();

  const {
    getCurrentRole,
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();

  const currentRole = getCurrentRole(customerId, siteId);
  const isSuperAdmin = currentRole === ACCESS_ROLES.SUPER_ADMIN;
  const isAccountAdmin = currentRole === ACCESS_ROLES.ACCOUNT_ADMIN;
  const isSiteManager = currentRole === ACCESS_ROLES.SITE_MANAGER;

  const disabledDelete = !(isSuperAdmin || isAccountAdmin || isSiteManager);

  const reportType = row?.reportType;
  const isFavorite = row?.isFavorite;
  const id = row?.id;

  const createdBy =
    row.createdBy === null ? SYSTEM : `${row?.createdBy?.firstName} ${row?.createdBy?.lastName}`;

  // const handleEdit = () => {
  //   removeHtmlElement();
  // };

  const { formattedDate, formattedTime } = formatTime(row?.updatedAt);

  const onHandleFavouriteClick = async () => {
    if (!customerId) return;

    try {
      handleFavoriteClick(row.id);
      await ReportsService.updateReportTemplate(id, customerId, {
        isFavorite: !isFavorite,
        reportType: reportType,
      });
    } catch (error) {
      console.error(error);
      handleFavoriteClick(row.id, true);
    }
  };

  const menuPopoverItems = (
    <>
      {/* <MenuPopoverItem
        icon={<DuplicateCopyIcon />}
        customContent={'Duplicate'}
        handleClick={handleEdit}
        testId="edit-button"
      /> */}
      <MenuPopoverItem
        icon={<Delete color={'error'} />}
        customContent={
          <Typography variant="body2" color="error">
            Delete
          </Typography>
        }
        handleClick={onToggle}
        testId="delete-button"
      />
    </>
  );

  const getReportTypeIcon = (reportType: ReportType) => {
    switch (reportType) {
      case ReportType.ASSET:
        return <AssetReportIcon />;
      case ReportType.PDF:
        return <PDFReportIcon />;
      case ReportType.DATA:
        return <DataReportIcon />;
      default:
        return <PDFReportIcon />;
    }
  };

  const setPath = (reportType: string) => {
    if (reportType === 'ASSET') return REPORTS_ASSET_PATH;
    else if (reportType === 'DATA') return REPORTS_DATA_PATH;
    else return REPORTS_PDF_PATH;
  };

  return (
    <>
      <DeleteConfirmationPopup
        state={state}
        onToggle={onToggle}
        title={
          <>
            Are you sure you wish to delete the {row.name} template?
            <br />
            You will lose access to all of the saved reports.
          </>
        }
        onDelete={handleDelete}
      />
      <TableRow
        onClick={() => {
          if (htmlElement) {
            return;
          }

          sessionStorage.setItem('report_type', row.reportType);
          searchParams.set('templateId', row.id);
          navigate(`${REPORTS_BUILD_PREFIX}/${setPath(row.reportType)}?${searchParams.toString()}`);
        }}
        sx={{ cursor: 'pointer' }}
        hover
        selected={selected}
        data-sm="table-row"
      >
        <TableCell data-sm="type-row" sx={{ ...tableCellStyle, width: '20px' }}>
          {getReportTypeIcon(row.reportType)}
        </TableCell>
        <TableCell data-sm="name-row" sx={tableCellStyle}>
          <Typography variant="subtitle2">{row.name ?? '-'}</Typography>
        </TableCell>
        <TableCell data-sm="created-by-row" sx={tableCellStyle}>
          <Typography variant="subtitle2">{createdBy}</Typography>
        </TableCell>
        <TableCell data-sm="modified-row" sx={tableCellStyle}>
          <Stack alignItems={'flex-start'} justifyContent={'center'}>
            <Typography variant="subtitle2">{formattedDate ?? '-'}</Typography>
            <Typography variant="overline" sx={{ color: theme.palette.grey[500] }}>
              {formattedTime ?? '-'}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell data-sm="favorite-row" sx={{ ...tableCellStyle, width: '20px' }}>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              onHandleFavouriteClick();
            }}
          >
            {row.isFavorite ? <FavoriteIcon /> : <UnfavoriteIcon />}
          </IconButton>
        </TableCell>
        {!disabledDelete && (
          <TableCell
            data-sm="context-menu-row"
            align="right"
            sx={{ ...tableCellStyle, width: '20px' }}
          >
            <IconButton
              data-sm="context-menu-button"
              color={htmlElement ? 'inherit' : 'default'}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                addHtmlElement(event.currentTarget);
              }}
            >
              <MoreVerticalFillIcon />
            </IconButton>
            <MenuPopover open={htmlElement} onClose={removeHtmlElement} arrow="right-top">
              {menuPopoverItems}
            </MenuPopover>
          </TableCell>
        )}
      </TableRow>
    </>
  );
};
