import { useState, useEffect } from 'react';
import { Asset, AssetTypes } from 'src/features/asset-details/types';
import { AssetService } from 'src/services';

type Props = {
  customerId: string | null;
  assetType: AssetTypes;
};

export const useFetchAndSetAssets = ({ customerId, assetType }: Props) => {
  const [assets, setAssets] = useState<{
    loaded: boolean;
    data: { label: string; value: string }[];
  }>({ loaded: false, data: [] });

  useEffect(() => {
    if (!customerId) {
      setAssets({ loaded: false, data: [] });
      return;
    }

    const fetchAssets = async () => {
      try {
        const data = await AssetService.getAll(customerId, undefined, {
          customerId,
          assetType,
        });

        setAssets({
          loaded: true,
          data:
            data.length > 0
              ? data.map((asset: Asset) => ({ label: asset.assetName, value: asset.id }))
              : [],
        });
      } catch (error) {
        console.error(error);
        setAssets({ loaded: true, data: [] });
      }
    };

    fetchAssets();
  }, [customerId, assetType]);

  return { assets };
};
