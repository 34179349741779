import { Box, Button, Stack, TextField, Typography, useTheme } from '@mui/material';
import { StyledReportsHeader, btnsBox, filedsBox, inputFiled } from './style';
import { FunctionComponent, useEffect, useState } from 'react';
import { useResponsive } from 'src/hooks';
import { useAuthentication } from 'src/features/authentication/context';
import { useTimezoneSelect, allTimezones } from 'react-timezone-select';
import { useFormContext } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { RHFAutocomplete, RHFTextField } from 'src/components/minimals/form';
import { ApiResourceState } from '../../types';
import useQueryParamsActions from 'src/hooks/useQueryParamsActions';
import { useTemplateState } from '../../context/templateContext';
import { AssetService, ReportsService } from 'src/services';
import { parseReportTemplatePayload } from '../../utils/parseReportTemplatePayload';
import { useTemplateCache } from '../../context/templateCacheContext';
import { useFilterSites } from '../../context/sitesContext';
import { ACCESS_ROLES } from 'src/config';
import { REPORT_TEMPLATE_DROPDOWN, SiteDropdownOption } from './config';
import { NewSite } from 'src/types/sites';
import { SitesService } from 'src/services/sites';
import { AssetTypes } from 'src/features/asset-details/types';

export const ReportsHeader: FunctionComponent<{
  title: string;
  disableActions: boolean;
  onUndo: (sites: any) => void;
}> = ({ title, onUndo, disableActions }) => {
  const theme = useTheme();
  const isPDFReport = sessionStorage.getItem('report_type') === 'PDF';

  const methods = useFormContext();
  const [customerSites, setCustomerSites] = useState<{
    loaded: boolean;
    value: SiteDropdownOption[] | null;
  }>({ loaded: false, value: [] });
  const { trigger } = methods;

  const { get } = useQueryParamsActions();
  const tempalteId = get('templateId');
  const { checkTemplateForChanges } = useTemplateState();
  const stale = checkTemplateForChanges(methods.watch());
  const isTablet = useResponsive('down', 'lg');
  const { enqueueSnackbar } = useSnackbar();
  const [reportState, setReportState] = useState<{
    status: ApiResourceState;
    error: Error | null;
    data: any;
  }>({
    status: 'INITIAL',
    error: null,
    data: null,
  });

  const { getValues, watch, setValue } = useFormContext();
  const { get: getQueryParam, append } = useQueryParamsActions();
  const templateId = getQueryParam('templateId');
  const labelStyle = 'original';
  const { isLoading, sites } = useFilterSites();
  const { options } = useTimezoneSelect({ labelStyle, timezones: allTimezones });
  const templateCacheState = useTemplateCache();

  const {
    getCurrentRole,
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();
  const currentRole = getCurrentRole(customerId, siteId);
  const isSuperAdmin = currentRole === ACCESS_ROLES.SUPER_ADMIN;
  const isAccountAdmin = currentRole === ACCESS_ROLES.ACCOUNT_ADMIN;
  const isSiteManager = currentRole === ACCESS_ROLES.SITE_MANAGER;
  const [assetSiteIds, setAssetSiteIds] = useState<string[]>([]);

  const disabledPermisionSaveBtn = !(isSuperAdmin || isAccountAdmin || isSiteManager);
  const data = getValues();
  const watchedSiteId = watch('data.siteId');
  useEffect(() => {
    if (isPDFReport && typeof watchedSiteId === 'string' && customerSites.loaded) {
      const selectedSite = customerSites.value?.find((site) => site.value === watchedSiteId);

      if (selectedSite) {
        setValue('data.siteId', selectedSite, {
          shouldValidate: true,
          shouldDirty: false,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSites.loaded, watchedSiteId, isPDFReport, setValue]);

  useEffect(() => {
    const selectedSiteId = watch('header.site')?.value;
    const selectedSite = customerSites.value?.find((site) => site.value === selectedSiteId);

    if (selectedSite?.location?.timeZone) {
      const matchingTimezone = options.find(
        (option) => option.value === selectedSite.location.timeZone
      );

      if (matchingTimezone) {
        setValue('header.timeZone', matchingTimezone, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('header.site'), customerSites.value]);

  useEffect(() => {
    if (!isPDFReport || !customerId) return;

    AssetService.getAll(customerId, undefined, {
      customerId,
      assetType: AssetTypes.MBR_TRAIN,
    })
      .then((data: any[]) => {
        const siteIds = data.map((item) => item.siteId);
        setAssetSiteIds(siteIds);
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(`Error checking if customer: ${customerId} has MBR Asset's`, {
          variant: 'error',
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, isPDFReport]);

  const filteredCustomerSites = customerSites.value?.filter((site) =>
    assetSiteIds.includes(site.value)
  );
  useEffect(() => {
    if (!customerId) return;
    if (!isPDFReport) return;
    SitesService.getAll({
      customerId,
    })
      .then((data: NewSite[]) => {
        if (data.length > 0) {
          const sites = data.map((site) => ({
            label: site.siteName,
            value: site.id,
            location: site.location,
          }));
          setCustomerSites({ loaded: true, value: sites });
        } else {
          setCustomerSites({ loaded: true, value: [] });
        }
      })
      .catch((error) => {
        setCustomerSites({ loaded: true, value: null });
        console.error(error);
      });
  }, [customerId, isPDFReport]);

  const testId = `${data?.header?.reportType?.id?.toLowerCase()}-report-time-zone`;
  const assetIdsLength = data?.data?.assetIds?.length;

  const onSaveHandler = async () => {
    const isValid = await trigger();

    if (!isValid) {
      return enqueueSnackbar('Please fill out all required fields', { variant: 'error' });
    }
    if (!data.header.timeZone) {
      return enqueueSnackbar('Timezone is required!', { variant: 'error' });
    }
    if (isPDFReport && assetIdsLength === 0) {
      return enqueueSnackbar('Asset is required', { variant: 'error' });
    }
    if (isPDFReport && data?.data?.siteId?.length === 0) {
      return enqueueSnackbar('Site is required', { variant: 'error' });
    }
    setReportState((prevState) => ({ ...prevState, status: 'LOADING' }));
    try {
      const report = templateId
        ? await ReportsService.updateReportTemplate(
            templateId,
            customerId!,
            parseReportTemplatePayload(data.header.reportType?.id, {
              ...data,
              title,
            })
          )
        : await ReportsService.saveReportTemplate(
            customerId!,
            parseReportTemplatePayload(data.header.reportType?.id, { ...data, title })
          );
      templateCacheState[1]({});
      append('templateId', report.id);
      setReportState((prevState) => ({ ...prevState, data: report, status: 'SUCCESS' }));
      enqueueSnackbar('Report template successfully ' + (templateId ? 'updated' : 'saved') + '!', {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar(
        'Could not ' + (templateId ? 'update' : 'save') + ' report template!\n' + err.message,
        { variant: 'error' }
      );
      setReportState((prevState) => ({ ...prevState, error: err, status: 'ERROR' }));
    }
  };

  return (
    <StyledReportsHeader theme={theme} isTablet={isTablet}>
      <Stack
        flexDirection="column"
        sx={{
          ...filedsBox,
          alignItems: isTablet ? 'center' : '',
        }}
      >
        <Typography fontWeight="700" variant="body1">
          {watch('header.reportType')?.label || 'Loading...'}
        </Typography>

        {!isPDFReport ? (
          <RHFAutocomplete
            sx={{ ...inputFiled, width: isTablet ? '100%' : '280px' }}
            name="header.timeZone"
            isOptionEqualToValue={(option, value) => option.value === value.value}
            rules={{ required: 'The field is required' }}
            options={options}
            disableClearable
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  'data-sm': testId,
                }}
                fullWidth
                label={'Time Zone *'}
              />
            )}
          />
        ) : (
          <>
            <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={3}>
              <RHFAutocomplete
                sx={{ ...inputFiled, width: isTablet ? '100%' : '280px' }}
                name="data.reportTemplate"
                options={REPORT_TEMPLATE_DROPDOWN}
                rules={{ required: 'The field is required' }}
                disableClearable
                size="small"
                renderInput={(p) => (
                  <TextField
                    {...p}
                    inputProps={{
                      ...p.inputProps,
                      'data-sm': testId,
                    }}
                    fullWidth
                    label={'Report Template *'}
                  />
                )}
              />
              <RHFAutocomplete
                sx={{ ...inputFiled, width: isTablet ? '100%' : '280px' }}
                name="data.siteId"
                isOptionEqualToValue={(option, value) => option.value === value.value}
                options={filteredCustomerSites || []}
                rules={{ required: 'The field is required' }}
                disableClearable
                size="small"
                renderInput={(p) => (
                  <TextField
                    {...p}
                    inputProps={{
                      ...p.inputProps,
                      'data-sm': testId,
                    }}
                    fullWidth
                    label={'Site'}
                  />
                )}
              />

              <RHFAutocomplete
                sx={{ ...inputFiled, width: isTablet ? '100%' : '280px' }}
                name="header.timeZone"
                rules={{ required: 'The field is required' }}
                options={options}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                disableClearable
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      'data-sm': testId,
                    }}
                    fullWidth
                    label={'Time Zone'}
                  />
                )}
              />

              <RHFTextField
                label="Required data interval (s)"
                name="data.dataInterval"
                testId="data-interval-field"
                rules={{
                  required: 'The field is required',
                  min: { value: 1, message: 'Value must be greater than 0' },
                }}
                size="small"
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                    step: 0.1,
                  },
                }}
              />
              <RHFTextField
                label="Outlier tolerance (%)"
                name="data.outlierTolerance"
                testId="data-outlier-tolerance-field"
                rules={{
                  required: 'The field is required',
                  min: { value: 0, message: 'Value must be greater than 0' },
                  max: { value: 100, message: 'Value must be less than 100' },
                }}
                size="small"
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                    step: 0.1,
                  },
                }}
              />
              <RHFTextField
                label="MLSS logs/week"
                name="data.mlssPerWeek"
                rules={{
                  required: 'The field is required',
                  min: { value: 1, message: 'Value must be greater than 0' },
                }}
                testId="data-mlsslogs-field"
                size="small"
              />
            </Box>
          </>
        )}
      </Stack>

      <Stack flexDirection="row" justifyContent="center" sx={btnsBox}>
        {(tempalteId && (stale === 'INVALID' || !stale)) || (!tempalteId && !stale) ? (
          <Button
            disabled={disableActions || isLoading}
            onClick={() => onUndo(sites)}
            size="medium"
            variant="outlined"
          >
            Undo Changes
          </Button>
        ) : (
          <></>
        )}
        <Button
          disabled={
            reportState.status === 'LOADING' ||
            stale === 'INVALID' ||
            stale ||
            disableActions ||
            isLoading ||
            disabledPermisionSaveBtn
          }
          size="medium"
          variant="contained"
          onClick={onSaveHandler}
          data-sm="save-template-btn"
        >
          {reportState.status === 'LOADING' ? 'Saving template...' : 'Save Template'}
        </Button>
      </Stack>
    </StyledReportsHeader>
  );
};
