import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import { MenuPopover } from 'src/components';

type StyleTypes = {
  theme: Theme;
};

const trendWidgetBox = {
  boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
};

const trendWidgetWrapper = {
  borderRadius: '8px',
  padding: '10px 20px 20px 20px',
  margin: '1.5rem 0',
};

const timelineBoxDetails = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '1rem',
};

const hamburgerBox = {
  cursor: 'pointer',
  paddingTop: '1rem',
  marginLeft: '10px',
};

const StyledMenuPopover = styled(MenuPopover)(({ theme }: StyleTypes) => ({
  marginTop: '45px',
  padding: '1rem',
  display: 'flex',
  flexDirection: 'column' as const,
  alignItems: 'flex-start',
}));

const tooltipItemBox = { display: 'flex', alignItems: 'center', cursor: 'pointer' };
const tooltipItemText = { marginLeft: '10px' };
const removeBox = {
  paddingLeft: '4px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
};

const rightSideContentBox = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 'auto',
};

const tooltipSyncItemBox = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginLeft: '-10px',
};

const syncBox = {
  display: 'flex',
};

const tooltipText = {
  fontWeight: 400,
  fontSize: '14px',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
};

export {
  trendWidgetWrapper,
  timelineBoxDetails,
  hamburgerBox,
  StyledMenuPopover,
  tooltipItemBox,
  tooltipItemText,
  removeBox,
  rightSideContentBox,
  trendWidgetBox,
  tooltipSyncItemBox,
  syncBox,
  tooltipText,
};
