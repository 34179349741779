import { ReportsService } from 'src/services';
import { AssetPropertyOption, FormDataType, ReportType } from '../types';
import { useAuthentication } from 'src/features/authentication/context';
import useQueryParamsActions from 'src/hooks/useQueryParamsActions';
import { useEffect, useState } from 'react';
import { useTemplateCache } from '../context/templateCacheContext';
import {
  parsePDFTemplateResponse,
  parseTemplateResponse,
} from '../utils/parseDataTemplateResponse';
import { api } from 'src/api.client';
import {
  ASSET_REPORT_INITIAL_STATE,
  DATA_REPORT_INITIAL_STATE,
  PDF_REPORT_INITAL_STATE,
} from '../config/config';
import { UseFormReturn } from 'react-hook-form';
import assetTypes from '../../asset-details/data/asset_types.json';

interface ITemplateQueryProps {
  onTemplateError: (error: Error) => void;
  methods: UseFormReturn<FormDataType, any>;
}

interface ITemplateQueryReturnData {
  data: any;
  loaded: boolean;
  fetched: boolean;
  assetProperties: AssetPropertyOption[];
}

const useTemplateQuery: (props: ITemplateQueryProps) => ITemplateQueryReturnData = ({
  onTemplateError,
}) => {
  const { get } = useQueryParamsActions();
  const [templateCache, setTemplateCache] = useTemplateCache();
  const reportType = sessionStorage.getItem('report_type');
  const templateId = get('templateId');
  let defaultState;

  if (reportType === ReportType.ASSET) {
    defaultState = ASSET_REPORT_INITIAL_STATE.data;
  } else if (reportType === ReportType.PDF) {
    defaultState = PDF_REPORT_INITAL_STATE.data;
  } else {
    defaultState = DATA_REPORT_INITIAL_STATE.data;
  }

  const [templateData, setTemplateData] = useState<ITemplateQueryReturnData>({
    loaded: false,
    fetched: false,
    assetProperties: [],
    data: {
      state: defaultState,
    },
  });

  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();

  useEffect(() => {
    (async () => {
      setTemplateData((prevState) => ({ ...prevState, fetched: false }));
      const getTemplate = async () => {
        const template = await ReportsService.getReportTemplateById(
          reportType as string,
          customerId!,
          templateId!
        );

        return template;
      };
      const fetchAssetProperties = async () => {
        const assetPropertiesByAssetType = Promise.all(
          assetTypes.map(async (asset) => {
            const assetTags = await api.get(
              'sites/' + customerId + '/sites/' + siteId + '/assets/tags/' + asset.value
            );

            return [
              asset.value,
              assetTags.data.map((tag: any) => ({
                value: tag.id,
                label: tag.name,
                available: true,
              })),
            ];
          })
        );

        return assetPropertiesByAssetType;
      };
      let assetProps: any = [];

      try {
        if (reportType === 'ASSET') {
          if (!templateId || !templateCache[templateId]) {
            assetProps = await fetchAssetProperties();
          } else {
            assetProps = templateCache[templateId].assetProperties;
          }
        }
        if (templateId) {
          let temp: any;
          if (templateCache[templateId]) {
            temp = templateCache[templateId].template;
            assetProps = templateCache[templateId].assetProperties;
          } else {
            temp = await getTemplate();
            if (temp.reportType === 'ASSET') {
              if (!assetProps.length) {
                assetProps = await fetchAssetProperties();
              }
            }
            sessionStorage.setItem('report_type', temp.reportType);
            setTemplateCache((prevState) => ({
              ...prevState,
              [templateId]: { template: temp, assetProperties: assetProps },
            }));
          }
          const { timezone, name, ...restTemplate } = temp;
          const parsedData =
            restTemplate.reportType === 'PDF'
              ? parsePDFTemplateResponse(restTemplate, assetProps)
              : parseTemplateResponse(restTemplate, assetProps);
          setTemplateData((prevState) => ({
            loaded: true,
            fetched: true,
            assetProperties: assetProps,
            data: {
              ...prevState.data,
              reportType: temp.reportType,
              state: parsedData,
              title: name,
              timezone,
            },
          }));
        } else {
          setTemplateData((prevState) => ({
            ...prevState,
            loaded: true,
            fetched: true,
            assetProperties: assetProps,
          }));
        }
      } catch (err) {
        onTemplateError(err);
      }
    })();
  }, [
    templateId,
    reportType,
    customerId,
    onTemplateError,
    siteId,
    setTemplateData,
    setTemplateCache,
    templateCache,
  ]);

  return templateData;
};

export default useTemplateQuery;
