import { Location } from 'src/types/sites';

export const REPORT_TEMPLATE_DROPDOWN = [
  { label: 'Status Report', value: 'Status Report' },
  { label: 'Performance Report', value: 'Performance Report' },
];

export type SiteDropdownOption = {
  label: string;
  value: string;
  location: Location;
};
