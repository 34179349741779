import { useTheme } from '@mui/material';
import { CriticalIcon, InfoIcon, OfflineIcon, WarningIcon } from 'src/assets';
import { LightTooltip } from 'src/components';
import { SuccessIcon } from 'src/features/theme/overrides/CustomIcons';
import { AlarmPriority } from 'src/types/alarms';

type HandlePriorityProps = {
  priority?: AlarmPriority;
};

export const HandlePriority = ({ priority }: HandlePriorityProps) => {
  const theme = useTheme();
  if (priority === 'Critical') {
    return (
      <LightTooltip title="Critical" enterTouchDelay={0} placement="left-end" arrow>
        <div>
          <CriticalIcon fill={theme.palette.error.main} />
        </div>
      </LightTooltip>
    );
  } else if (priority === 'Offline') {
    return (
      <LightTooltip title="Offline" enterTouchDelay={0} placement="left-end" arrow>
        <div>
          <OfflineIcon fill={theme.palette.error.main} />
        </div>
      </LightTooltip>
    );
  } else if (priority === 'Info') {
    return (
      <LightTooltip title="Info" enterTouchDelay={0} placement="left-end" arrow>
        <div>
          <InfoIcon fill={theme.palette.info.main} />
        </div>
      </LightTooltip>
    );
  } else if (priority === 'Warning') {
    return (
      <LightTooltip title="Warning" enterTouchDelay={0} placement="left-end" arrow>
        <div>
          <WarningIcon fill={theme.palette.warning.main} />;
        </div>
      </LightTooltip>
    );
  } else {
    return (
      <LightTooltip title="No active alarms" enterTouchDelay={0} placement="left-end" arrow>
        <div>
          <SuccessIcon htmlColor={theme.palette.success.main} />
        </div>
      </LightTooltip>
    );
  }
};
