import { SelectDropdownData } from 'src/types';

type AssetType = 'Pump' | 'Wet Well' | 'Water Tower' | string;

export type BaseAsset = {
  assetName: string;
  assetType: AssetType;
};

export type NewAsset = {
  assetProperties?: NewAssetProperty[];
  assignedTags?: NewAssignedTag[];
  assignedAlarmTags?: NewAlarmTag[];
} & BaseAsset;

export type UpdateAsset = NewAsset;

export type Asset = {
  id: string;
  createdAt: string;
  updatedAt: string;
  assetProperties?: EditAssetProperty[];
  assignedTags?: EditAssignedTag[];
  assignedAlarmTags?: EditAlarmTag[];
  reportLimits?: {
    maxAirFlow?: any;
    maxTmp?: any;
    minPermeability?: any;
    minMlss?: any;
    maxMlss?: any;
    mlssDataRecordsPerWeek?: any;
    maxPermeateLinePressure?: any;
  };
} & NewAsset;

type ValueType = 'TEXT' | 'NUMERIC' | 'OPTION' | 'DATE' | string;

export enum AssetTypes {
  PUMP = 'PUMP',
  WATER_TOWER = 'WATER_TOWER',
  WET_WELL = 'WET_WELL',
  MBR_TRAIN = 'MBR_TRAIN',
  VALVE = 'VALVE',
}

export enum FieldType {
  TEXT = 'TEXT',
  NUMERIC = 'NUMERIC',
  OPTION = 'OPTION',
  DATE = 'DATE',
}

export type AssetPropertyResponse = {
  id: string;
  name: string;
  valueType: ValueType;
  unit?: string;
  units?: string[];
  defaultUnit?: string;
  defaultValue?: string;
  parentType?: string;
  required?: string;
};

export type NewAssetProperty = {
  name: string;
  value: string;
  unit: string;
};
export type NewAssignedTag = {
  assignedTagId: string;
  tagName: string;
  assignedGatewayId: string;
  tagIndicator?: string;
  tagIndicatorPosition?: number;
  tagIndicatorValue?: string;
};

export type NewAlarmTag = {
  assignedAlarmTagId: string;
  alarmTagName: string;
  assignedAlarmGatewayId: string;
  level?: string | null;
};

export type EditAssetProperty = {
  id: string;
} & NewAssetProperty;

export type EditAssignedTag = {
  id: string;
} & NewAssignedTag;

export type EditAlarmTag = {
  id: string;
} & NewAlarmTag;

export type AssignedTagResponse = {
  id: string;
  name: string;
};

export type AssetDetailsFormProps = {
  handleConfirmationWindow?: VoidFunction;
  isEdit?: boolean;
  currentAsset?: { value: Asset | null; loaded: boolean };
};

export type AssetTags = {
  dataSourceId: string;
  tagData: SelectDropdownData[];
};

export type UnmappedTags = {
  id: string;
  gatewayId: string;
  assignedTagTitle: string;
};

export type AlarmTagResponse = AssignedTagResponse;
