import { Theme } from '@mui/material';
import { WarningIcon, InfoIcon as InfoIcon1 } from 'src/features/theme/overrides/CustomIcons';
import { AlarmWarningIcon } from 'src/assets';

export const getAlarmPriorityOptions = (theme: Theme) => [
  {
    label: 'Critical',
    value: 'Critical',
    icon: <AlarmWarningIcon fill={theme.palette.error.main} />,
  },
  {
    label: 'Warning',
    value: 'Warning',
    icon: <WarningIcon htmlColor={theme.palette.warning.main} />,
  },
  {
    label: 'Info',
    value: 'Info',
    icon: <InfoIcon1 htmlColor={theme.palette.secondary.main} />,
  },
];
