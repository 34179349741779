import { useResponsive } from 'src/hooks';
import useReportPreviewState from '../hooks/useReportPreviewState';
import ReportPreviewLoading from '../components/report-preview-loading/ReportPreviewLoading';
import NoReportGeneratedComponent from '../components/no-report-generated/NoReportGeneratedComponent';
import { ReportPreview } from '../components';
import dayjs from 'dayjs';
import { Stack } from '@mui/material';
import { PDFReportSidebar } from '../components/pdf-report-sidebar/PDFReportSidebar';
import { useAuthentication } from 'src/features/authentication/context';
import { AssetTypes } from 'src/features/asset-details/types';
import { useFetchAndSetAssets } from '../hooks/useFetchAndSetAssets';

export const PDFReportPage = () => {
  const {
    customerId: { value: customerId },
  } = useAuthentication();
  const isTablet = useResponsive('down', 'lg');
  const { state, handleReportCancel, handleReportsPreview } = useReportPreviewState();
  const assetType = AssetTypes.MBR_TRAIN;
  const { assets } = useFetchAndSetAssets({ customerId, assetType });

  let content;
  if (state.status === 'LOADING') {
    content = <ReportPreviewLoading onCancel={handleReportCancel} />;
  } else if (!state.data) {
    content = <NoReportGeneratedComponent />;
  } else {
    content = (
      <ReportPreview
        data={state.data}
        title={`${state.data.reportName} - ${dayjs(state.data.timeframe.start).format(
          'MMM D YYYY'
        )} - ${dayjs(state.data.timeframe.end).format('MMM D YYYY')}`}
      />
    );
  }
  return (
    <Stack
      flexDirection={isTablet ? 'column' : 'row'}
      gap="24px"
      flex="1"
      sx={{
        alignItems: 'flex-start',
      }}
    >
      <PDFReportSidebar onPreview={handleReportsPreview} assets={assets} />
      {content}
    </Stack>
  );
};

export default PDFReportPage;
