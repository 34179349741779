import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { boxWrapper } from './style';
import { useLayoutEffect, useRef, useState } from 'react';
import { StyledMapContainer } from '../map/styles';
import { TileLayer, Marker, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapNavigationControls } from '../map-navigation-controls';
import { PinIcon } from './assets/PinIcon';
import { CustomerService } from 'src/services';
import { getAddressStringFromLocation } from '../../utils/getAddressString';
import { useQuery } from '@tanstack/react-query';
import { LocationType } from '../../types';

type PinOnMapDialogProps = {
  lat?: string;
  lng?: string;
  alt?: string;
  locationDetails?: (lat: number, lng: number) => void;
  onClose?: () => void;
  customerId?: string | null;
  defaultLocation?: LocationType;
  isOpen: boolean;
};

export const PinOnMapDialog = ({
  lat,
  lng,
  locationDetails,
  onClose,
  customerId,
  defaultLocation,
  isOpen,
}: PinOnMapDialogProps) => {
  const mapRef = useRef<L.Map>(null);

  const [markerPosition, setMarkerPosition] = useState<[number, number] | null>(
    lat && lng ? [parseFloat(lat), parseFloat(lng)] : null
  );

  const { data: customerData } = useQuery({
    queryKey: ['customer', customerId],
    queryFn: () => CustomerService.getById(customerId!),
    enabled: !!customerId,
    gcTime: 120000, // 2 min cache time
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const MapClickHandler = () => {
    useMapEvents({
      click: (e) => {
        setMarkerPosition([e.latlng.lat, e.latlng.lng]);
      },
    });
    return null;
  };

  useLayoutEffect(() => {
    const requiredFields: (keyof LocationType)[] = ['city', 'country', 'state'];

    const useDefaultLocation =
      defaultLocation && requiredFields.every((field) => defaultLocation[field]?.trim() !== '');

    if (useDefaultLocation) {
      fetchAndSetBounds(defaultLocation).catch(console.error);
    } else if (customerData?.businessAddress) {
      const address = getAddressStringFromLocation(customerData.businessAddress);
      fetchAddressBounds(address)
        .then((bounds) => {
          if (bounds && mapRef.current) {
            mapRef.current.fitBounds(bounds);
          }
        })
        .catch(console.error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, customerData, defaultLocation]);

  const fetchAndSetBounds = async (location: LocationType) => {
    try {
      const address = getAddressStringFromLocation(location);
      const bounds = await fetchAddressBounds(address);
      if (bounds && mapRef.current) {
        mapRef.current.fitBounds(bounds);
      }
    } catch (error) {
      console.error('Failed to fetch bounds for location:', error);
    }
  };

  const onSubmit = () => {
    if (markerPosition && locationDetails) locationDetails(markerPosition[0], markerPosition[1]);
    onClose?.();
  };

  const fetchAddressBounds = async (address: string): Promise<L.LatLngBounds | null> => {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
        address
      )}&format=json&addressdetails=1`
    );
    const data = await response.json();

    if (data.length > 0) {
      const { boundingbox } = data[0];
      return L.latLngBounds(
        [parseFloat(boundingbox[0]), parseFloat(boundingbox[2])],
        [parseFloat(boundingbox[1]), parseFloat(boundingbox[3])]
      );
    }
    return null;
  };

  return (
    <Box sx={{ ...boxWrapper }}>
      <Box sx={{ padding: '24px' }} component="header">
        <Typography variant="h5">Select the location on the map to set coordinates</Typography>
      </Box>

      <Box id="map" sx={{ maxHeight: 900, height: '400px', width: '100%' }}>
        <StyledMapContainer
          center={markerPosition ?? [51.505, -0.09]}
          zoom={12}
          zoomControl={false}
          maxZoom={18}
          scrollWheelZoom={true}
          ref={mapRef}
        >
          <TileLayer
            attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          />
          {markerPosition ? (
            <Marker
              position={markerPosition}
              icon={PinIcon}
              draggable={true}
              eventHandlers={{
                dragend: (e) => {
                  const marker = e.target;
                  const { lat, lng } = marker.getLatLng();
                  const normalizedLng = L.Util.wrapNum(lng, [-180, 180]);
                  setMarkerPosition([lat, normalizedLng]);
                },
              }}
            />
          ) : null}

          <MapClickHandler />
          <MapNavigationControls
            bounds={[
              [19.833549, 17.218101571997703],
              [50, 78.6382],
            ]}
          />
        </StyledMapContainer>
      </Box>

      <Grid container spacing={3} sx={{ pt: '24px' }}>
        <Grid item sm={4}>
          <TextField
            name="latitude"
            label="Latitude"
            fullWidth
            size="small"
            value={markerPosition?.[0] ?? ''}
            InputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            name="longitude"
            label="Longitude"
            fullWidth
            size="small"
            value={markerPosition?.[1] ?? ''}
            InputProps={{ readOnly: true }}
          />
        </Grid>
      </Grid>

      <Stack sx={{ width: '100%', pt: '24px' }} flexDirection={'row'} justifyContent={'flex-end'}>
        <Button size={'medium'} variant="outlined" sx={{ marginRight: '10px' }} onClick={onClose}>
          Cancel
        </Button>
        <Button size={'medium'} variant="contained" onClick={onSubmit}>
          Submit
        </Button>
      </Stack>
    </Box>
  );
};
