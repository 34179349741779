//PATHS
const ROOT_PATH = '/';
const ADD_CUSTOMER_ACCOUNT_PATH = '/add-customer-account';
const LOGIN_PATH = '/login';
const ASSIGN_FORM = '/assign-form';
const BWT_DEMO_PATH = '/login/bwt';
const USER_PROFILE_PATH = '/profile';
const CHANGE_PASSWORD = '/change-password';
const EDIT_CUSTOMER_ACCOUNT_PATH = '/edit-customer-account';
const FORGOT_PASSWORD = '/forgot-password';
const FORGOT_PASSWORD_LINK_SENT = '/link-sent';
const SITES_MANAGEMENT_PATH = '/sites-management';
const SITES_MAP_PATH = '/sites-map';
const SITES_DASHBOARD_PATH = '/sites-management/dashboard';
const USER_ACCOUNTS_PATH_ROOT = '/user-accounts';
const USER_ACCOUNTS_PATH_INVITE = '/invite-user';
const ADD_SITE_PATH = '/add-site';
const LOGS = '/logs';
const SITE_INFO_PATH = '/site-info';
const USER_ACCOUNTS_PATH_EDIT = '/edit-user-account';
const GATEWAY_PATH_ROOT = '/gateways';
const GATEWAY_PATH_ADD = '/add-gateway';
const ASSET_PATH_ROOT = '/assets';
const ASSET_PATH_ADD = '/add-asset';
const ASSET_PATH_EDIT = '/edit-asset';
const PUMP_REPORT_PATH = '/reports/pump-report';
const TENANT_SWITCH_PATH = '/tenant-switch';
const ACCOUNT_ISSUE_PATH = '/account-issue';
const TRENDS_PATH = '/trends';
const INTEGRATIONS_PATH = '/integrations';
const SET_PROFILE_PATH = '/set-profile';
const WELCOME_PATH = '/welcome';
const CONTROLLERS_PATH_ROOT = '/controllers';
const CONTROLLERS_PATH_ADD = '/add-controller';
const CONTROLLERS_PATH_EDIT = '/edit-controller';
const PAGE_NOT_FOUND = '/*';
const ALARMS_NOTIFICATIONS_PATH = '/alarms-notifications';
const ALARMS_NOTIFICATIONS_ADD_PATH = '/add-alarms-notification-group';
const ALARMS_NOTIFICATIONS_EDIT_PATH = '/edit-alarms-notification-group';
const ONE_CLICK_ALARM_ACKNOWLEDGEMENT_PATH = '/acknowledge-alarm';
const FORMS_PATH = '/forms';
const FORMS_ADD_PATH = '/add-form';
const FORMS_EDIT_PATH = '/edit-form';
const SITE_TREND_PATH = '/trends/customer/site';

//VARIABLES
const REPORTS = '/reports';
const REPORTS_NEW_PATH = '/reports';
const REPORTS_HISTORY = 'history';
const REPORTS_SCHEDULE = 'schedule';
const REPORTS_BUILD_PREFIX = '/build-report';
const REPORTS_ASSET_PATH = 'asset';
const REPORTS_DATA_PATH = 'data';
const REPORTS_PDF_PATH = 'pdf';

const ALARM_RULES_PATH_ROOT = '/alarm-rules';
const ALARM_RULES_PATH_ADD = '/add-alarm-rule';
const INPUTS_PATH_ROOT = '/inputs';
const RULES_PATH = '/rules';
const ALARMS_PATH = 'alarms';
const SUBSCRIPTIONS_PATH = '/subscriptions';
const USERS_PATH = '/users';
//TEXT
const ROOT_HEADER = 'Customer Account Management';
const ADD_CUSTOMER_ACCOUNT_HEADER = 'Add Customer Account';
const EDIT_CUSTOMER_ACCOUNT_HEADER = 'Edit Customer Account';
const INVITE_USER_ACCOUNT_HEADER = 'Invite User';
const EDIT_USER_ACCOUNT_HEADER = 'Edit User';
const USER_ACCOUNTS_HEADER = 'User Management';
const SITES_MANAGEMENT_HEADER = 'Sites';
const ADD_GATEWAY_HEADER = 'Add Gateway';
const EDIT_GATEWAY_HEADER = 'Edit Gateway';
const ADD_SITES_HEADER = 'Add Site';
const GATEWAYS_MANAGEMENT_HEADER = 'Gateways';
const ASSETS_HEADER = 'Assets';
const ASSETS_ADD_HEADER = 'Add Asset';
const ASSETS_EDIT_HEADER = 'Edit Asset';
const ASSETS_MANAGEMENT_HEADER = 'Assets';
const PUMP_REPORT_HEADER = 'Pump Report';
const REPORT_NEW_HEADER = 'Reports';
const ALARM_RULES_MANAGEMENT_HEADER = 'Alarm Rules';
const ADD_ALARM_RULE_HEADER = 'Add Alarm Rule';
const EDIT_ALARM_RULE_HEADER = 'Edit Alarm Rule';
const ALARMS_HEADER = 'Alarms';
const ADD_ALARM_NOTIFICATIONS = 'Add Alarm Notification Group';
const EDIT_ALARM_NOTIFICATIONS = 'Edit Alarm Notification Group';
const MANAGE_NOTIFICATIONS = 'Notification Management';
const TRENDS_HEADER = 'Trends';
const CONTROLLERS_MANAGEMENT_HEADER = 'Controllers';
const CONTROLLERS_ADD_HEADER = 'Add Controller';
const CONTROLLERS_EDIT_HEADER = 'Edit Controller';
const PAGE_NOT_FOUND_HEADER = 'Page Not Found';
export {
  ROOT_PATH,
  ADD_CUSTOMER_ACCOUNT_PATH,
  LOGIN_PATH,
  BWT_DEMO_PATH,
  SITES_MANAGEMENT_PATH,
  SITES_MAP_PATH,
  SITES_DASHBOARD_PATH,
  SITES_MANAGEMENT_HEADER,
  ROOT_HEADER,
  ADD_CUSTOMER_ACCOUNT_HEADER,
  CHANGE_PASSWORD,
  EDIT_CUSTOMER_ACCOUNT_PATH,
  EDIT_CUSTOMER_ACCOUNT_HEADER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_LINK_SENT,
  INVITE_USER_ACCOUNT_HEADER,
  USER_ACCOUNTS_PATH_ROOT,
  USER_ACCOUNTS_PATH_INVITE,
  USER_ACCOUNTS_PATH_EDIT,
  EDIT_USER_ACCOUNT_HEADER,
  USER_ACCOUNTS_HEADER,
  ADD_SITE_PATH,
  LOGS,
  ADD_SITES_HEADER,
  SITE_INFO_PATH,
  SITE_TREND_PATH,
  GATEWAY_PATH_ADD,
  REPORTS_HISTORY,
  ADD_GATEWAY_HEADER,
  GATEWAY_PATH_ROOT,
  GATEWAYS_MANAGEMENT_HEADER,
  ASSET_PATH_ROOT,
  ASSETS_HEADER,
  ASSET_PATH_ADD,
  ASSET_PATH_EDIT,
  PAGE_NOT_FOUND,
  REPORTS_BUILD_PREFIX,
  PAGE_NOT_FOUND_HEADER,
  ASSETS_ADD_HEADER,
  EDIT_GATEWAY_HEADER,
  ASSETS_MANAGEMENT_HEADER,
  PUMP_REPORT_PATH,
  PUMP_REPORT_HEADER,
  REPORTS,
  ASSETS_EDIT_HEADER,
  ALARM_RULES_PATH_ADD,
  ADD_ALARM_RULE_HEADER,
  ALARM_RULES_MANAGEMENT_HEADER,
  ALARM_RULES_PATH_ROOT,
  INPUTS_PATH_ROOT,
  RULES_PATH,
  ALARMS_PATH,
  ALARMS_HEADER,
  MANAGE_NOTIFICATIONS,
  REPORTS_DATA_PATH,
  EDIT_ALARM_RULE_HEADER,
  TENANT_SWITCH_PATH,
  ACCOUNT_ISSUE_PATH,
  SUBSCRIPTIONS_PATH,
  USERS_PATH,
  TRENDS_PATH,
  TRENDS_HEADER,
  INTEGRATIONS_PATH,
  SET_PROFILE_PATH,
  WELCOME_PATH,
  CONTROLLERS_MANAGEMENT_HEADER,
  ASSIGN_FORM,
  CONTROLLERS_PATH_ROOT,
  CONTROLLERS_PATH_ADD,
  CONTROLLERS_ADD_HEADER,
  CONTROLLERS_PATH_EDIT,
  CONTROLLERS_EDIT_HEADER,
  USER_PROFILE_PATH,
  ALARMS_NOTIFICATIONS_PATH,
  ADD_ALARM_NOTIFICATIONS,
  EDIT_ALARM_NOTIFICATIONS,
  ALARMS_NOTIFICATIONS_ADD_PATH,
  ALARMS_NOTIFICATIONS_EDIT_PATH,
  ONE_CLICK_ALARM_ACKNOWLEDGEMENT_PATH,
  REPORTS_NEW_PATH,
  REPORT_NEW_HEADER,
  REPORTS_ASSET_PATH,
  FORMS_PATH,
  FORMS_ADD_PATH,
  FORMS_EDIT_PATH,
  REPORTS_SCHEDULE,
  REPORTS_PDF_PATH,
};
