import { ReportType } from 'src/features/reports';
import {
  ASSET_REPORT_STRING_NAME,
  DATA_REPORT_STRING_NAME,
  PDF_REPORT_STRING_NAME,
} from 'src/features/reports/config/config';

export const returnReportType = (reportType: string) => {
  if (reportType === ReportType.DATA) {
    return DATA_REPORT_STRING_NAME;
  } else if (reportType === ReportType.PDF) {
    return PDF_REPORT_STRING_NAME;
  } else {
    return ASSET_REPORT_STRING_NAME;
  }
};
